import { Component, Prop } from 'vue-property-decorator'
import { BaseThreadUserDto } from 'booksprout'
import ConfirmDialog from '../../../../components/ConfirmDialog'

@Component
export default class BlockUser extends ConfirmDialog {
  @Prop({ type: Object }) public blockUser!: BaseThreadUserDto
  @Prop({ type: String }) public message!: string

  public reportUser = false

  public no () {
    this.hide()
  }

  public yes () {
    this.$emit('ok', {
      reportUser: this.reportUser
    })
    this.hide()
  }
}
