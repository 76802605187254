import { render, staticRenderFns } from "./BlockUser.vue?vue&type=template&id=1be805cf"
import script from "./BlockUser.ts?vue&type=script&lang=ts&external"
export * from "./BlockUser.ts?vue&type=script&lang=ts&external"
import style0 from "./BlockUser.sass?vue&type=style&index=0&prod&lang=sass&external"


/* normalize component */
import normalizer from "!../../../../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports
import QDialog from 'quasar/src/components/dialog/QDialog.js';
import QIcon from 'quasar/src/components/icon/QIcon.js';
import QAvatar from 'quasar/src/components/avatar/QAvatar.js';
import qInstall from "../../../../../../../../../../node_modules/@quasar/app/lib/webpack/runtime.auto-import.js";
qInstall(component, 'components', {QDialog,QIcon,QAvatar});
