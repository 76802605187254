var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"bs-message-thread__message q-py-xs row",class:{'bs-message-thread__message--alt': this.alt}},[_vm._t("beforeMessage"),_c('div',{staticClass:"user-info full-width flex justify-start items-center"},[_c('div',{staticClass:"avatar"},[_c('q-avatar',{attrs:{"size":"1.875rem"}},[_c('bs-img',{attrs:{"placeholder-src":"/cdn/no-image.png","ratio":"1","src":_vm.post.sender.image,"alt":_vm.post.sender.name}})],1)],1),_c('div',{staticClass:"user-name bs-par-m-b"},[_vm._v("\n      "+_vm._s(_vm.post.sender.name)+"\n      "),(_vm.viewOnly)?_c('span',[_vm._v("\n        - "+_vm._s(_vm.formatDate(_vm.post.createdDate, 'hh:mm · DD/MM/YYYY'))+"\n      ")]):_vm._e(),(_vm.viewOnly && _vm.post.state === _vm.MODULE_CONSTANTS.MESSAGES.STATE.FLAGGED)?_c('span',[_vm._v("\n        -\n      ")]):_vm._e(),(_vm.viewOnly && _vm.post.state === _vm.MODULE_CONSTANTS.MESSAGES.STATE.FLAGGED)?_c('span',{staticClass:"text-bs-r"},[_vm._v("\n        Reported Message\n      ")]):_vm._e()])]),_c('div',{staticClass:"bs-par-m flex items-center",class:{
      'full-width': _vm.shouldShowFlaggedMessage
    },on:{"mouseover":function($event){$event.stopPropagation();_vm.showFlag = true},"mouseout":function($event){_vm.showFlag = false}}},[_c('div',{staticClass:"bs-message-thread__message-body rounded-borders q-mt-sm q-pa-md relative-position",class:{
        'bg-bs-lb text-white': this.alt === false,
        'bg-bs-lg-b text-bs-grey-m': this.alt === true,
        'bg-bs-r text-white': _vm.viewOnly && _vm.post.state === _vm.MODULE_CONSTANTS.MESSAGES.STATE.FLAGGED,
        'bs-message-thread__message-body--flag col': _vm.shouldShowFlaggedMessage
      }},[(!_vm.viewOnly)?_c('transition',{staticClass:"absolute-right",attrs:{"name":"fade"}},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.showFlag),expression:"showFlag"}],staticClass:"flag-post"},[_vm._t("after")],2)]):_vm._e(),(_vm.shouldShowFlaggedMessage)?_c('div',{staticClass:"bs-message-thread__message-body--flagged absolute-center bg-bs-r q-pa-md border-radius bs-par-s-b"},[_vm._v("\n        "+_vm._s(_vm.$tc('modules.messages.labels.messageFlagged'))+"\n      ")]):_vm._e(),_c('span',{staticClass:"text"},[_vm._v(_vm._s(_vm.post.message))])],1)])],2)
}
var staticRenderFns = []

export { render, staticRenderFns }