import { Component } from 'vue-property-decorator'
import { QDialog } from 'quasar'
import SettingsCrudModule from '../modules/settings/pages/mixins/settingsCrudModule'
import { SET_USER } from '../store/actions/auth'
import { UserDto } from 'booksprout'

@Component
export default class EnterAccountNameDialog extends SettingsCrudModule {
  accountName = ''

  get dialog (): QDialog {
    return this.$refs.enterAccountNameDialog as QDialog
  }

  show () {
    this.dialog.show()
  }

  hide () {
    this.dialog.hide()
  }

  onDialogHide () {
    this.$emit('hide')
  }

  cancel () {
    this.goBack()
    this.hide()
  }

  async confirm () {
    this.$emit('ok')
    this.hide()
  }

  saveAccountName () {
    if (this.accountName && this.accountName.length > 2) {
      return this.crudService.update({
        id: this.mimicOrAuthenticatedUser.id,
        name: this.accountName
      }).then((user: UserDto) => {
        this.$store.dispatch(SET_USER, user).then(() => {
          this.confirm()
        })
      }).catch(e => {
        this.showError(e.message)
      })
    }
    return Promise.resolve()
  }
}
