import { Component, Prop } from 'vue-property-decorator'
import { ArcServiceInterface, BaseCrudServiceInterface, inject } from 'booksprout-app'
import { CrudModuleMixin } from 'src/modules/_base/components/mixin/crudModule'
import { ArcDto, BookDto, validStr, ArcSite, ArcReviewSites } from 'booksprout'

@Component
export default class AddArcLinks extends CrudModuleMixin<BookDto> {
  @Prop({ type: Object }) readonly arc!: ArcDto
  @Prop({ type: Boolean }) readonly showAll!: boolean

  @inject('ArcService')
  arcService!: ArcServiceInterface<ArcDto>

  @inject('BookService')
  crudService!: BaseCrudServiceInterface<BookDto>

  crudModel = new BookDto()
  urlModelName!: 'book'

  localShowAll: boolean = false

  get siteLinks (): ArcReviewSites {
    const arcSites = this.arcService.getArcSites(this.localShowAll, this.flagIsRequired)
    const filteredSites = arcSites.sites.filter(f => {
      if (this.arc.arcType === this.MODULE_CONSTANTS.ARCS.TYPE.AUDIO_BOOK) {
        // if author selected both US and UK
        if (validStr(this.arc.usDownloadCodes) && validStr(this.arc.ukDownloadCodes)) {
          return f.site === this.AUDIBLE_US || f.site === this.AUDIBLE_UK || f.site === this.AUDIO_BOOK_BUB || f.site === this.AUDIO_GOOD_READS
        }
        // only US
        if (validStr(this.arc.usDownloadCodes)) {
          return f.site === this.AUDIBLE_US || f.site === this.AUDIO_BOOK_BUB || f.site === this.AUDIO_GOOD_READS
        }
        // only UK
        if (validStr(this.arc.ukDownloadCodes)) {
          return f.site === this.AUDIBLE_UK || f.site === this.AUDIO_BOOK_BUB || f.site === this.AUDIO_GOOD_READS
        }

        // Authors Direct & Kobo
        return f.site === this.AUDIO_BARNES ||
          f.site === this.AUDIO_BOOK_BUB ||
          f.site === this.AUDIO_GOOD_READS ||
          f.site === this.AUDIO_GOOGLE ||
          f.site === this.AUDIO_ITUNES ||
          f.site === this.AUDIO_KOBO
      }

      return this.MODULE_CONSTANTS.ARCS.REVIEWS.AUDIO_FLAGS.indexOf(f.site) === -1
    })

    return {
      requiredCount: arcSites.requiredCount,
      sites: filteredSites
    }
  }

  flagIsRequired (fieldName: any, flag: number): boolean {
    if (flag === this.AMAZON) {
      // @ts-ignore
      return (!validStr(this.arc.book[fieldName]) && !validStr(this.arc.book?.linkAmazonAsinPrint) && (this.arc.reviewFlags & flag || this.arc.optionalReviewFlags & flag))
    }
    // In order to only show the fields which need filling in but to not disappear when we start typing (As they would have content)
    // we are checking the this.arc.book fields instead of this.crudModel. This allows us to remember what values they had when the
    // component was loaded. The next time it's opened it'll have the updated values and will in turn hide the ones that don't need
    // filling in.
    // @ts-ignore
    return (validStr(this.arc.book[fieldName]) === false && (this.arc.reviewFlags & flag || this.arc.optionalReviewFlags & flag))
  }

  getDocId () {
    return this.arc.book?.id as number
  }

  siteLinkDescription (arcSite: ArcSite): string {
    if (!arcSite) return ''

    return this.$t(`modules.books.formFields.${arcSite.fieldName}.title`).toString()
  }

  siteLinkPlaceholder (arcSite: ArcSite): string {
    if (!arcSite) return ''

    return this.$t(`modules.books.formFields.${arcSite.fieldName}.placeholder`).toString()
  }

  linkAutoSave () {
    const arcSitesLeftForReview = this.arcService.getArcSites(this.localShowAll, (fieldName: string, flag: number): boolean => {
      // @ts-ignore
      return !validStr(this.crudModel[fieldName]) && (
        this.arc.reviewFlags & flag ||
        this.arc.optionalReviewFlags & flag
      )
    })

    return this.autoSave().then(() => {
      if (arcSitesLeftForReview.requiredCount === 0) {
        return this.arcService.updateDueCutOffDate(this.arc)
          .then(() => this.$emit('linkUpdated'))
          .catch(e => {
            this.showError(e.message)
          })
      }
      return Promise.resolve()
    }).catch(e => {
      this.showError(e.message)
    })
  }

  created () {
    this.localShowAll = this.showAll
  }
}
