import { Component, Prop } from 'vue-property-decorator'
import { QDialog } from 'quasar'
import { CommonBaseMixin } from './mixins/base'

@Component
export default class ConfirmDialog extends CommonBaseMixin {
  @Prop({ type: String }) readonly heading!: string
  @Prop({ type: String }) readonly bodyHtml!: string
  @Prop({ type: String }) readonly yesText!: string
  @Prop({ type: String }) readonly noText!: string
  @Prop({ type: Boolean }) readonly invertColors!: boolean
  @Prop({ type: String }) readonly modelName!: string
  @Prop({ type: String }) readonly modelImage!: string

  get yesLabel (): string {
    return this.yesText || this.$tc('system.actions.yes')
  }

  get noLabel (): string {
    return this.noText || this.$tc('system.actions.no')
  }

  show () {
    (this.$refs.confirmDialog as QDialog).show()
  }

  hide () {
    (this.$refs.confirmDialog as QDialog).hide()
  }

  onDialogHide () {
    this.$emit('hide')
  }

  yes () {
    this.$emit('ok')
    this.hide()
  }

  no () {
    this.hide()
  }
}
