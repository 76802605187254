import { Component, Prop } from 'vue-property-decorator'
import { CommonBaseMixin } from 'booksprout-app'
import { bsConstants, MessagePostDto } from 'booksprout'

@Component
export default class ChatMessage extends CommonBaseMixin {
  @Prop({ type: Object }) readonly post!: MessagePostDto
  @Prop({ type: Boolean }) readonly alt!: boolean
  @Prop({ type: Boolean }) readonly viewOnly!: boolean

  showFlag = false

  get shouldShowFlaggedMessage () {
    return !this.viewOnly
      && this.post.state === bsConstants.MESSAGES.STATE.FLAGGED
      && this.mimicOrAuthenticatedUser.id !== this.post.userId
  }
}
