import { Component, Prop } from 'vue-property-decorator'
import { CommonBaseMixin, inject, MessageThreadServiceInterface } from 'booksprout-app'
import { BaseThreadUserDto, MessageThreadDto } from 'booksprout'

@Component({
  data () {
    return {
      recipient: void 0
    }
  }
})
export default class ThreadRecipient extends CommonBaseMixin {
  @Prop({ type: Number }) public readonly threadId!: number

  @inject('MessageThreadService')
  public threadService!: MessageThreadServiceInterface<MessageThreadDto>

  public recipient!: BaseThreadUserDto
  public showManageReader = false

  public async mounted () {
    const thread = await this.threadService.getById(this.threadId)
    if (thread) {
      this.recipient = this.threadService.getOtherThreadParticipant(thread, this.mimicOrAuthenticatedUser.id)
      this.showManageReader = thread.reviewerUserId !== this.mimicOrAuthenticatedUser.id
    }
  }
}
