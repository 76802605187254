import { render, staticRenderFns } from "./AddLinks.vue?vue&type=template&id=37a6b5dc"
import script from "./AddLinks.ts?vue&type=script&lang=ts&external"
export * from "./AddLinks.ts?vue&type=script&lang=ts&external"
import style0 from "./AddLinks.sass?vue&type=style&index=0&prod&lang=sass&external"


/* normalize component */
import normalizer from "!../../../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports