import { Component, Prop } from 'vue-property-decorator'
import { CommonBaseMixin } from 'booksprout-app'
import { inject, MessageThreadServiceInterface, ArcServiceInterface } from 'booksprout-app'
import { ArcClaimDto, ArcDto, MessageThreadDto } from 'booksprout'
import ArcSites from '../../../../components/ArcSites.vue'

@Component({
  data () {
    return {
      thread: void 0,
      claim: void 0
    }
  },
  components: {
    ArcSites
  }
})
export default class ThreadArcDetail extends CommonBaseMixin {
  @Prop({ type: Number }) readonly threadId!: number

  @inject('MessageThreadService')
  threadService!: MessageThreadServiceInterface<MessageThreadDto>

  @inject('ArcService')
  arcService!: ArcServiceInterface<ArcDto>

  thread!: MessageThreadDto
  claim: ArcClaimDto | undefined

  getReviewLink (): void {
    const user = this.mimicOrAuthenticatedUser
    // if author is viewing the thread
    if (user.authorRole && this.thread.authorUser.id === user.id) {
      const arcUrl = this.linkService.addSearch(this.linkService.adminView('review-campaign', this.thread.arc.id), this.thread.reviewerUser.name + '')

      if (this.isReviewerApp) {
        this.navigateToOtherApp('publisher' + arcUrl)
      } else {
        void this.$router.push(arcUrl)
      }
    }

    if (this.thread.reviewerUserId === user.id) {
      // update local storage so when user gets to your arcs page, they see the category the claim is in
      void this.$router.push(this.linkService.frontEndRelative('my-review-copies') + `#?groupByStatus=${this.claim?.status || 0}`)
    }
  }

  get arcLink (): string {
    const user = this.mimicOrAuthenticatedUser

    // if author is viewing the thread
    if (user.authorRole && this.thread.authorUser.id === user.id) {
      if (this.isReviewerApp) {
        return this.linkService.frontEndRelative('review-copy', 'view', this.thread.arc.id, this.thread.arc.book?.title)
      }

      return this.linkService.adminView('review-campaign', this.thread.arc.id)
    }

    if (this.thread.reviewerUserId === user.id) {
      return this.linkService.frontEndRelative('my-review-copies') + `#?groupByStatus=${this.claim?.status || 1}`
    }

    return ''
  }

  mounted () {
    // TODO: Avoid the double fetch but for now we need the reviewerId from the thread.
    this.threadService.getById(this.threadId).then(t => {
      if (t) {
        this.threadService.getByIdForUser(this.threadId, t.reviewerUserId).then(thread => {
          if (thread) {
            this.thread = thread
            this.arcService.getClaim(thread.arc.id, thread.reviewerUserId).then(claim => {
              this.claim = claim
            }).catch(e => {
              this.showError(e.message)
            })
          }
        }).catch(e => {
          this.showError(e.message)
        })
      }
    }).catch(e => {
      this.showError(e.message)
    })
  }
}
