import { Component } from 'vue-property-decorator'
import { CommonCrudModuleMixin, inject, SET_MIMIC_USER } from 'booksprout-app'
import { AuthServiceInterface } from 'booksprout-app'
import { UserServiceInterface } from 'booksprout-app'
import { SET_USER } from 'booksprout-app'
import { UserDto, UserEmailDto } from 'booksprout'

@Component
export default class SettingsCrudModule extends CommonCrudModuleMixin<UserDto> {
  @inject('AuthService')
  public authService!: AuthServiceInterface

  @inject('UserService')
  public crudService!: UserServiceInterface<UserDto>
  public crudModel = new UserDto()
  public urlModelName = 'user'

  public loadDoc () {
    // id doesn't matter here as the service will only return the currently authenticated user.

    this.crudService.getById(-1, {
      mimicUser: this.isMimic && this.authenticatedUser.isAdmin
    }).then((doc: UserDto | null) => {
      this.assignLoadedDoc(doc)
    }).catch(e => {
      this.showError(e.message)
    })
  }

  public assignLoadedDoc (doc: UserDto | null) {
    // Make sure we have the default email address at the top.
    if (doc !== null) {
      doc.userEmails?.sort((a: UserEmailDto, b: UserEmailDto) => {
        return a.isDefault
          ? -1
          : a.status < b.status ? -1 : 0
      })

      this.crudModel = doc
      this.onLoadDoc()
    }
  }

  public save (): Promise<UserDto> {
    return this.crudService.update(this.crudModel).then((user: UserDto) => {
      if (this.isMimic && this.authenticatedUser.isAdmin) {
        return this.$store.dispatch(SET_MIMIC_USER, user).then(() => {
          return user
        })
      } else {
        return this.$store.dispatch(SET_USER, user).then(() => {
          return user
        })
      }
    })
  }
}
