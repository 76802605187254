import { Component, Inject, Prop } from 'vue-property-decorator'
import { getUrlHash, MessageThreadDto, MessageThreadGetArgs, validStr } from 'booksprout'
import { CommonBaseListModuleMixin, inject, MessageThreadServiceInterface } from 'booksprout-app'
import NoResults from 'booksprout-app/src/components/NoResults.vue'

@Component({
  inject: [
    'reloadCount'
  ],
  components: {
    NoResults
  }
})
export default class MessageThreads extends CommonBaseListModuleMixin<MessageThreadDto> {
  @Prop({ type: String }) public readonly state!: string
  @Prop({ type: Number }) public readonly userId!: number

  // Comes from ../Index.ts
  @Inject() public reloadCount!: () => void

  @inject('MessageThreadService')
  public crudService!: MessageThreadServiceInterface<MessageThreadDto>

  public list: MessageThreadDto[] = []
  public urlModelName = 'message'

  public searchValue = ''
  public hoverId = 0
  public selectedThreads: number[] = []
  public searchSet = false
  sortDescending = false

  public get heading () {
    return this.$t(`modules.messages.labels.${this.state}`) + ` (${this.totalRows || 0})`
  }

  public get selectAllState () {
    if (this.selectedThreads.length === this.list?.length && this.list?.length > 0) return true
    else if (this.selectedThreads.length > 0) return void 0
    else return false
  }

  public async getData () {
    const params = getUrlHash(this.isMobileApp ? '?' : void 0)

    if (!validStr(this.searchValue) && params?.needle !== void 0 && !this.searchSet) {
      this.searchValue = params.needle
      // prevent the search being applied when the user has deleted the text in the search box
      this.searchSet = true
    }

    const args: MessageThreadGetArgs = {
      needle: this.searchValue
    }

    switch (this.state) {
      case 'unread':
        args.state = this.MODULE_CONSTANTS.MESSAGES.STATE.NEW
        break
      default:
        break
    }

    if (this.userId) {
      args.reviewUserId = this.userId
    }

    const data = await this.crudService.get(this.makeGetArgs(args))
    return data
  }

  public doSearch () {
    return this.load()
  }

  clearSearch () {
    // Doing this as the user "might" have come from the xConversations button click which scopes the whole component
    // to a user id.
    this.$router.push('/messages/inbox')
  }

  public doSort () {
    return this.load()
  }

  public updateReadStatus (thread: MessageThreadDto) {
    if (thread.state === this.MODULE_CONSTANTS.MESSAGES.STATE.NEW) {
      return this.crudService.markAsRead(thread.id).then(() => {
        this.reloadCount()
        return this.load()
      }).catch(e => {
        this.showError(e.message)
      })
    } else if (thread.state === this.MODULE_CONSTANTS.MESSAGES.STATE.READ) {
      return this.crudService.markAsUnread(thread.id).then(() => {
        this.reloadCount()
        return this.load()
      }).catch(e => {
        this.showError(e.message)
      })
    }

    return Promise.resolve()
  }

  public selectAllThreads () {
    this.selectedThreads = this.selectedThreads.length > 0 ? [] : this.list?.map(m => m.id)
  }

  public selectThread (thread: MessageThreadDto) {
    const currentIndex = this.selectedThreads.indexOf(thread.id)

    currentIndex > -1
      ? this.selectedThreads.splice(currentIndex, 1)
      : this.selectedThreads.push(thread.id)
  }

  public updateAllReadStatus () {
    const updateSelected = this.list?.filter(f => this.selectedThreads.includes(f.id))
    for (const thread of updateSelected) {
      this.updateReadStatus(thread)
    }
  }

  async markAsSeenAndShowThread (id: number) {
    this.crudService.markAsRead(id)
      .catch((error) => {
        console.error(error)
      }).finally(() => {
        // always redirect, regardless of success or failure
        this.$router.push('/messages/thread/' + id)
      })
  }
}
