import { Component, Provide } from 'vue-property-decorator'
import ThreadRecipient from './components/ThreadRecipient.vue'
import ThreadArcDetail from './components/ThreadArcDetail.vue'
import { CommonBaseMixin, inject, MessageThreadServiceInterface, SET_UNREAD_MESSAGE_COUNT } from 'booksprout-app'
import { MessageThreadDto } from 'booksprout'
import MessageThreads from './components/Threads.vue'
import MessageThread from './components/Thread.vue'
import { Getter } from 'vuex-class'

@Component({
  components: {
    MessageThreads,
    MessageThread,
    ThreadRecipient,
    ThreadArcDetail
  }
})
export default class MessageIndex extends CommonBaseMixin {
  @inject('MessageThreadService')
  public readonly messageThreadService!: MessageThreadServiceInterface<MessageThreadDto>

  @Getter('getUnreadMessageCount') public newCount!: number

  // Give our child components the ability to update the new count
  @Provide()
  public reloadCount () {
    this.messageThreadService.getUnreadThreadCount().then(count => {
      this.$store.dispatch(SET_UNREAD_MESSAGE_COUNT, count)
    }).catch(e => {
      this.showError(e.message)
    })
  }

  created () {
    this.setPageMeta('messages')
  }

  public mounted () {
    this.reloadCount()
  }
}
